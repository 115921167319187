@charset "UTF-8";


/* ********************************************************
	共通初期化CSS
******************************************************** */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400&display=swap");
html, body, div, header, footer, article, section, aside, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, figure {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html, body {
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  line-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

ol, ul, li {
  list-style: none;
}

img {
  -webkit-backface-visibility: hidden;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus {
  outline: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

.clear, div .clear {
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  clear: both;
}

/*---clearFix---*/
.clearFix {
  zoom: 1;
}

.clearFix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

@media print {
  body {
    zoom: 60%;
  }
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

/* ********************************************************
	共通初期化CSS
******************************************************** */
html, body, div, header, footer, article, section, aside, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, figure {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html, body {
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  line-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

ol, ul, li {
  list-style: none;
}

img {
  -webkit-backface-visibility: hidden;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus {
  outline: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

.clear, div .clear {
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  clear: both;
}

/*---clearFix---*/
.clearFix {
  zoom: 1;
}

.clearFix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

@media print {
  body {
    zoom: 60%;
  }
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

@font-face {
  font-family: 'Utsukushifont';
  /* フォーマットごとにパスを指定 */
  src: url("../../assets/font/utsukushifont.ttf") format("truetype");
  src: url("../../assets/font/utsukushifont.woff") format("woff");
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*-------------------------------
  width
-------------------------------*/
/*-------------------------------
 break-point
-------------------------------*/
/*-------------------------------
 browser
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
@font-face {
  font-family: 'Utsukushifont';
  /* フォーマットごとにパスを指定 */
  src: url("../../assets/font/utsukushifont.ttf") format("truetype");
  src: url("../../assets/font/utsukushifont.woff") format("woff");
}

body {
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  color: #666666;
  background: #ede2e1;
  -webkit-print-color-adjust: exact;
}

p, a {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.7em;
  word-break: normal;
  color: #666666;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: all .4s;
}

a:hover {
  opacity: 0.8;
  -moz-opacity: 0.8;
  cursor: pointer;
}

.tel_a:hover {
  cursor: text;
  opacity: 1;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .tel_a:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 667px) {
  .tel_a:hover {
    cursor: pointer;
  }
}

.font_b {
  font-weight: bold;
}

.cl_or {
  color: #e4935d;
}

.link_a {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: bold;
  color: #e4935d;
  text-decoration: underline;
}

.txt_in {
  text-indent: -1em;
  margin-left: 1em;
}

.txt_c {
  text-align: center;
}

.contents_link {
  padding-top: 205px;
  margin-top: -205px;
}

.pc {
  display: block !important;
}

.tab {
  display: none !important;
}

.sp {
  display: none !important;
}

.pc_tab {
  display: block !important;
}

.tab_sp {
  display: none !important;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .pc {
    display: none !important;
  }
  .pc_tab {
    display: block !important;
  }
  .tab {
    display: block !important;
  }
  .tab_sp {
    display: block !important;
  }
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 667px) {
  .pc {
    display: none !important;
  }
  .pc_tab {
    display: none !important;
  }
  .tab {
    display: none !important;
  }
  .tab_sp {
    display: block !important;
  }
  .sp {
    display: block !important;
  }
}

.fx_js {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 667px) {
  .fx_js {
    flex-direction: column;
  }
}

.gmap {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.gmap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
