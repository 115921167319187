@charset "utf-8";
@import "_format";
@import "_mixin";
@import "_variables";


body {
  font-family: $font_yumin;
  color: $clr_base;
  background: $clr_spnk;
  -webkit-print-color-adjust: exact;
}
p,a {
  @include fz(16);
  font-weight: 500;
  line-height: 1.7em;
  word-break: normal;
  color: $clr_base;
}

//--- anchor ---//
a {
  text-decoration: none;
  cursor: pointer;
  transition: all .4s;
}

a:hover {
  opacity: 0.8;
  -moz-opacity: 0.8;
  cursor: pointer;
}

.tel_a:hover {
  cursor: text;
  opacity: 1;
  @include tab {
    cursor: pointer;
  }
  @include sp {
    cursor: pointer;
  }
}

//--- font ---//
.font_b {
  font-weight: bold;
}
.cl_or {
  color: $clr-org;
}
.link_a {
  @include fz(18);
  font-weight: bold;
  color: $clr-org;
  text-decoration: underline;
}
.txt_in {
  @include txt_in; 
}
.txt_c {
  text-align: center;
}
.contents_link {
  padding-top: 205px;
  margin-top:-205px;
}
//--- Layout ---//
.pc {display: block !important;}
.tab {display: none !important;}
.sp {display: none !important;}
.pc_tab {display: block !important;}
.tab_sp {display: none !important;}

@include tab {
.pc {display: none !important;}
.pc_tab {display: block !important;}
.tab {display: block !important;}
.tab_sp {display: block !important;}
.sp {display: none !important;}
}

@include sp {
.pc {display: none !important;}
.pc_tab {display: none !important;}
.tab {display: none !important;}
.tab_sp {display: block !important;}
.sp {display: block !important;}
}

.fx_js {
  display: flex;
  justify-content: space-between;
  @include sp {
    flex-direction: column;
  }
}

//--- gmap ---//
.gmap {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.gmap iframe {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}





